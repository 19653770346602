
// hide on viewport
if ($(window).width() <= 640) {
  $('#nav').attr('hidden', 'true');
} else {
  $('#nav').removeAttr('hidden');
}

$(window).on('resize', function () {
  if ($(window).width() <= 640) {
      $('#nav').attr('hidden', 'true');
  } else {
      $('#nav').removeAttr('hidden');
  }
});

var menuClose = document.querySelector('.menu__close');
var menuIems = document.querySelectorAll('#nav li a');


function hideMenu() {
  if ($(window).width() <= 640) {
    menuClose.click();
  }
}

for ( var i = 0; i < menuIems.length; i++ ) {
  menuIems[i].addEventListener("click", hideMenu);
}


const totalFrames = 32;
const animationDuration = 1900;
const timePerFrame = animationDuration / totalFrames;
let timeWhenLastUpdate;
let timeFromLastUpdate;
let frameNumber = 1;

function step(startTime) {
  if (!timeWhenLastUpdate) timeWhenLastUpdate = startTime;
  timeFromLastUpdate = startTime - timeWhenLastUpdate;

  if (timeFromLastUpdate > timePerFrame) {
    $('.logo__anima').css('opacity', 0);
    $('.logo__anima-'+frameNumber).css('opacity', 1);
    timeWhenLastUpdate = startTime;

    if (frameNumber >= totalFrames) {
      frameNumber = 32;
    } else {
      frameNumber = frameNumber + 1;
    }        
  }

  requestAnimationFrame(step);
}

$(window).on('load', () => {
  requestAnimationFrame(step);
});